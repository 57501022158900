@import "npm:@clr/city/css/index.css";

/*/ Limble Added /*/
 .checkbox-container {
   display: block;
   position: relative;
   padding-left: 45px;
   cursor: pointer;
   font-size: 20px;
}
 
input[type=checkbox] {
   visibility: hidden;
}
 
.checkmark {
   position: absolute;
   top: 0;
   left: 0;
   height: 14px;
   width: 14px;
   background-color: #fff;
   border: 1px solid #cacaca;
   border-radius: 4px;
}
 
.checkbox-container:hover input ~ .checkmark {
   background-color: yellow;
}
 
.checkbox-container input:active ~ .checkmark {
   background-color: red;
}
 
.checkbox-container input:checked ~ .checkmark {
   background-color: green;
}
 
.checkmark:after {
   content: "";
   position: absolute;
   display: none;
}

.negative-padding-left-16 {
   margin-left: -42px;
}
 
.checkbox-container input:checked ~ .checkmark:after {
   display: block;
}
 
.checkbox-container .checkmark:after {
   left: 4px;
   bottom: 3px;
   width: 3px;
   height: 6px;
   border: solid white;
   border-width: 0 2px 2px 0;
   -webkit-transform: rotate(45deg);
   -ms-transform: rotate(45deg);
   transform: rotate(45deg);
}



/* This is for right-aligned languages */
.rightBodyAlignText {
   direction: rtl;
}

.side-padding {
   padding-left: 24px;
    padding-right: 24px;
}

@media all and (min-width: 1200px){
   .modal-content {
      margin-left: 54vw;
   }
}

.closeBtn {
   color: #ccc;
   float: right;
   font-size: 30px;
}

.closeBtn:hover,
.closeBtn:focus {
   color: #000;
   text-decoration: none;
   cursor: pointer;
}

@keyframes modalopen {
   from {
      opacity: 0;
   }

   to {
      opacity: 1;
   }
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
   margin: 0px;
   padding: 0px;
   box-sizing: border-box;
}

body,
html {
   height: 100%;
   font-family: "Clarity City", sans-serif;
   
}

/*---------------------------------------------*/
a {
   font-family: "Clarity City", sans-serif;
   font-size: inherit;
   color: #727374;
   margin: 0px;
   transition: all 0.4s;
   line-height: 1.5;
   -webkit-transition: all 0.4s;
   -o-transition: all 0.4s;
   -moz-transition: all 0.4s;
}

a:focus {
   outline: none !important;
}

a:hover {
   text-decoration: none;
   color: #429b1f;
   /* color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
	color: -o-linear-gradient(left, #21d4fd, #b721ff);
	color: -moz-linear-gradient(left, #21d4fd, #b721ff);
	color: linear-gradient(left, #21d4fd, #b721ff); */
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
   margin: 0px;
}

p {
   font-size: 14px;
   line-height: 1.7;
   color: #666666;
   margin: 0px;
}

ul,
li {
   margin: 0px;
   list-style-type: none;
}

/*---------------------------------------------*/
input[type="text"] {
   font: size 14px;
   font-family: "Clarity City", sans-serif;
}

input {
   outline: none;
   border: none;
}

textarea {
   outline: none;
   border: none;
}

textarea:focus,
input:focus {
   border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
   color: transparent;
}

input:focus:-moz-placeholder {
   color: transparent;
}

input:focus::-moz-placeholder {
   color: transparent;
}

input:focus:-ms-input-placeholder {
   color: transparent;
}

textarea:focus::-webkit-input-placeholder {
   color: transparent;
}

textarea:focus:-moz-placeholder {
   color: transparent;
}

textarea:focus::-moz-placeholder {
   color: transparent;
}

textarea:focus:-ms-input-placeholder {
   color: transparent;
}

input::-webkit-input-placeholder {
   color: #adadad;
}

input:-moz-placeholder {
   color: #adadad;
}

input::-moz-placeholder {
   color: #adadad;
}

input:-ms-input-placeholder {
   color: #adadad;
}

textarea::-webkit-input-placeholder {
   color: #adadad;
}

textarea:-moz-placeholder {
   color: #adadad;
}

textarea::-moz-placeholder {
   color: #adadad;
}

textarea:-ms-input-placeholder {
   color: #adadad;
}

/*---------------------------------------------*/
button {
   outline: none !important;
   border: none;
   background: transparent;
}

button:hover {
   cursor: pointer;
}
button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
iframe {
   border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt2 {
   font-size: 14px;
   color: #4F5052;
}

.bold-button {
   font-family: "Clarity City", sans-serif;
}


/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
   width: 100%;
   margin: 0 auto;
   display: flex;

}

@media all and (min-width: 1200px){

   .side-panel {
      background-color: #D0E8D6;
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      justify-content: end;
   }

   .panel-watermark {
      background-image: url(../logos/deco-shape.svg);
      background-repeat: no-repeat;
      background-position: right top;
      background-size: 40%;
     
   }

   
   .limble-slogan {
      display: flex;
      justify-content: center;
      padding-bottom:72px;
      width: 75%;
      font-family: "Clarity City", sans-serif;
      font-size: 48px;
      color: #152232;
   }

   #empowerTextSwoosh {
      display: inline-flex;
      flex-direction: column;
      margin-right: -10px;
      margin-bottom: -11;
   }

   #empowerImage {
      margin-top: -13px;
   }

   .empowerTextGreen {
      color: #289E49;
   }

} 

@media all and (max-width: 1200px){
   .empowerText, .limble-slogan {
      display: none;
      
   }
}

.fonts-caption {
   font-family: "Metropolis", sans-serif;
   font-size: 12px;
   line-height: 16px;
   font-weight: 500;
   letter-spacing: 0.4px;
   color: #727374;
}

.legal-terms-section {
   text-align: center;
   font-size: 12px;
   color: #727374;
}

.signup-section {
   text-align: center; 
   font-size: 14px;
   color: #727374;
}

.password-expired {
   display: none; color: red
}

.forgot-password-success {
   display: none; 
   color: green; 
   text-align: center;
}

.login-labels {
   font-family: "Clarity City", sans-serif;
   color: #152232;
   font-size: 14px;
   font-weight: bold;
}

.container-login100 {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   min-height: 100vh;
   background: #fff;
}

.wrap-login100 {
   width: auto;
   max-width: 420px;
   background: #fff;
   overflow: hidden;
   padding: 65px 55px 33px 55px;
   margin-left: auto;
   margin-right: auto;
}

/*------------------------------------------------------------------
[ Form ]*/

.login100-form {
   min-width: 320px;
}

.login100-form-title {
   text-align: center;
}

.login100-form-title i {
   font-size: 60px;
}



/*------------------------------------------------------------------
[ Input ]*/

.wrap-input {
   width: 100%;
   margin-bottom: 8px;
   display: inline-flex;
   justify-content: left;
   flex-direction: column;
   flex-wrap: wrap;
}


.wrap-input.has-error .login-labels {
   color: #e62737;
 }
 
 .wrap-input.has-error .input100 {
   box-shadow: 0 0 2px 0 #e62737;
 }
 
 .password-error {
   font-size: 12px;
   margin-top: 4px;
 }

.wrap-input > label, .hoverboundry > label {
   margin-bottom: 4px;
}

.input100 {
   font-family: "Clarity City", sans-serif;
   font-size: 15px;
   color: #555555;
   border: none;
   border-radius: 4px;
   margin-top: 8px;
   box-shadow: 0 0 2px 0 #888;
   display: block;
   width: 100%;
   height: 32px;
   background: transparent;
   padding: 0 5px;
   z-index: 1;
}

/*---------------------------------------------*/
.focus-input100 {
   position: absolute;
   display: block;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   pointer-events: none;
}

.focus-input100::before {
   content: "";
   display: block;
   position: absolute;
   bottom: -2px;
   left: 0;
   width: 0;
   height: 2px;

   -webkit-transition: all 0.4s;
   -o-transition: all 0.4s;
   -moz-transition: all 0.4s;
   transition: all 0.4s;

   background: #429b1f;
}

.focus-input100::after {
   font-family: "Clarity City", sans-serif;
   font-size: 15px;
   color: #999999;
   line-height: 1.2;

   content: attr(placeholder);
   display: block;
   width: 100%;
   position: absolute;
   top: -15px;
   left: 0px;
   padding-left: 5px;
   /* padding-bottom: 5px; */
   -webkit-transition: all 0.4s;
   -o-transition: all 0.4s;
   -moz-transition: all 0.4s;
   transition: all 0.4s;
}

.input100:focus + .focus-input100::after {
   top: -15px;
}

.input100:focus + .focus-input100::before {
   width: 80%;
}

.has-val.input100 + .focus-input100::after {
   top: -15px;
}

.has-val.input100 + .focus-input100::before {
   width: 80%;
}

/*---------------------------------------------*/

.btn-show-pass:hover {
   color: \##429b1f;
}

.btn-show-pass.active {
   color: #429b1f;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
   display: inline-flex;
   justify-content: center;
   align-items: center;
   border: none;
   border-radius: 6px;
   font-size: 14px;
   font-weight: 500;
   line-height: 1;
   text-decoration: none;
   transition: all 0.3s;
   background-color: #289e49;
   width: 100%;
   height: 48px;
   color: white;
   font-weight: bold;
   font-family: "Clarity City", sans-serif;
}


.sso-button {
   font-family: "Clarity City", sans-serif;
   display: inline-flex;
   justify-content: center;
   align-items: center;
   border: none;
   border-radius: 6px;
   cursor: pointer;
   line-height: 16px;
   font-size: 14px;
   font-weight: 500;
   line-height: 1;
   text-decoration: none;
   transition: all 0.3s;
   background-color: #fff;
   color: #4f5052;
   box-shadow: 0 0 2px 0 #888;
   width: 100%;
   height: 48px;
   margin-top: 8px;
}




/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 576px) {
   .wrap-login100 {
      padding: 0px 15px 33px 15px;
   }
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.alert-validate::before {
   content: attr(data-validate);
   position: absolute;
   max-width: 70%;
   background-color: #fff;
   border: 1px solid #c80000;
   border-radius: 2px;
   padding: 4px 25px 4px 10px;
   top: 50%;
   -webkit-transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   -o-transform: translateY(-50%);
   transform: translateY(-50%);
   right: 0px;
   pointer-events: none;

   font-family: "Clarity City", sans-serif;
   color: #c80000;
   font-size: 13px;
   line-height: 1.4;
   text-align: left;

   visibility: hidden;
   opacity: 0;

   -webkit-transition: opacity 0.4s;
   -o-transition: opacity 0.4s;
   -moz-transition: opacity 0.4s;
   transition: opacity 0.4s;
}

.alert-validate::after {
   content: "\f06a";
   font-family: FontAwesome;
   font-size: 16px;
   color: #c80000;

   display: block;
   position: absolute;
   background-color: #fff;
   top: 50%;
   -webkit-transform: translateY(-50%);
   -moz-transform: translateY(-50%);
   -ms-transform: translateY(-50%);
   -o-transform: translateY(-50%);
   transform: translateY(-50%);
   right: 5px;
}

.alert-validate:hover:before {
   visibility: visible;
   opacity: 1;
}

@media (max-width: 1200px) {
   .alert-validate::before {
      visibility: visible;
      opacity: 1;
   }
}


.sso:hover {
   background-color: #f1f1f1;
   color: #222;
}

.sso .okta {
   color: #009cdd;
   font-weight: 700;
}

#ieBanner {
   position: fixed;
   z-index: 999999;
   top: 0px;
   height: auto;
   padding: 25px;
   width: 100%;
   left: 50%;
   transform: translate(-50%, 0%);
   background-color: #c22627;
   border-radius: 0px;
   display: none;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   transition: all 1s;
   opacity: 0.95;
   color: #fff;
}

#ieBanner > p {
   color: #fff;
   font-size: 20px;
}

#ieBanner > p > a {
   color: #fff;
   font-size: 20px;
   text-decoration: underline;
}

#signUpDiv {
   margin-top: 8px;
}

#slogan {
   width: 650px;
   min-height: 150px;
   line-height: 56px;
   font-weight: 600;
}

#forgotMyPasswordBtn {
   cursor: pointer;
   color: #727374;
}

#forgotPwBtn {
   width: 100%;
}

#loading {
   display: none;
   justify-content: center;
}

.hoverboundry .unhideOnHover {
   display: none;
}

.hoverboundry:hover .unhideOnHover {
   display: inline;
   z-index: 2;
   position: relative;
}

.unhideOnHoverIcon {
  float: right; 
  margin-right: 4px; 
  margin-top: -26px; 
  z-index: 2;
}

.toggle-password {
   cursor: pointer;
}

.login-tools {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 16px;
}

.errors-container {
   color:#E62737;
   width: 100%;
   display: inline-flex;
   justify-content: left;
   flex-direction: column;
   font-family: "Clarity City", sans-serif;
}

.click-troubleshoot {
   font-family: "Clarity City", sans-serif;
   color:#5083D5;
}

#logoContainer {
   display: flex;
   justify-content: center;
}

#loadingForgotPassword, #loading {
   justify-content: center;
}

#forgotPasswordInstruction {
   font-family: "Clarity City", sans-serif;
   letter-spacing: 0px;
   color: #727374;
   font-size: 14px;
}

#ssoLoginErr {
   margin-top: 16px;
   font-family: "Clarity City", sans-serif;
   letter-spacing: 0.4px;
   color: #e62737;
}

/* ~~~~~~~~~~~~~~~~PASSWORD EXPIRED~~~~~~~~~~~~~~~~ */
#pwExpiredFormTitle {
   font-family: "Clarity City", sans-serif;
   letter-spacing: 0px;
   font-size: 14px;
   line-height: 1.25;
 }
 
 #pwExpiredLengthWarning {
   margin-top: 8px;
 }
 
 #updatePasswordBtn {
   margin-top: 16px;
 }
 
 #pwExpiredCurrentPassword {
   margin-bottom: 32px;
 }

 #updatePasswordMessage {
   text-align: center;
   margin-top: 10px;
   font-family: "Clarity City", sans-serif;
   font-size: 14px;
   font-weight: 500;
   line-height: 1.25;
 }

/* ESIGNATURE VERIFICATION */
#esignature-verification {
   background-color: transparent;
}

#esignature-verification .wrap-login100 {
   background-color: transparent;
   padding: 0;
   margin: 0;
   width: 100%;
   max-width: 100%;
   overflow: visible;
}

#esignature-verification .login100-form {
   background-color: #fff;
   padding: 32px;
   border: 2px solid #e8e8e8;
   border-radius: 8px;
   position: relative;
   overflow: hidden;
   margin-bottom: 16px;
}

#esignature-verification #logoContainer {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   padding: 0;
   pointer-events: none;
}

#esignature-verification #loading {
   width: 100%;
   justify-content: center;
   align-items: center;
   background-color: #fff;
}

#esignature-verification #main-title {
   display: inline-block;
   margin-bottom: 8px;
}

#esignature-verification #subtitle {
   line-height: 1.2;
}

#esignature-verification #errorContainer {
   padding: 0;
}

#esignature-verification #errorContainer > span {
   padding: 8px 0;
   font-size: 14px;
   line-height: 1.2;
}

#esignature-verification .wrap-input {
   margin-bottom: 0;
}

#esignature-verification .login-tools {
   flex-direction: column;
   align-items: flex-start;
}

#esignature-verification .login-tools a {
   color: #5083D5;
   line-height: 1.5;
}

#esignature-verification .sso-button {
   width: auto;
   height: auto;
   margin: 0;
   box-shadow: none;
   font-weight: 800;
}

#esignature-verification .btn-container > button {
   width: auto;
   min-width: 32px;
   height: 32px;
   padding: 8px 16px;
   font-weight: 400;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   box-shadow: 0 2px 4px #09182b59;
}

#esignature-verification #login-btn-container {
   display: flex;
   justify-content: flex-end;
}

#esignature-verification #sso-btn-container {
   display: none;
   justify-content: space-between;
}

#esignature-verification #forgot-password-btn-container {
   display: none;
   justify-content: space-between;
}
/* END ESIGNATURE VERIFICATION */